:root {
  --background-color: white;
  --dark-text-color: #000c;
  --subdued-text-color: #765e8e;
  --light-text-color: #ff3396a6;
  --button-text-color: whitesmoke;
  --inactive-button-text-color: #00000080;
  --highlight-color: hotpink;
  --button-color: hotpink;
  --button-shadow-color: #9feff7;
  --feedback-color: #f93999;
  --inactive-color: lightgrey;
  --selected-color: white;
  --done-color: #c1fec1;
  --fail-color: #e73a46;
  --title-font: "Bungee Shade", cursive;
  --text-font: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --input-left-padding: .25em;
  --colored-offset: .5em .5em 0 0 var(--button-shadow-color);
  --colored-offset-inactive: .5em .5em 0 0 var(--inactive-color);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  min-height: 100vh;
  font-family: var(--text-font);
  color: var(--dark-text-color);
}

body {
  background-color: #edf7f7;
  background-image: radial-gradient(at 27% 42%, #da71f4 0, #da71f400 50%), radial-gradient(at 31% 51%, #2eef7c 0, #2eef7c00 50%), radial-gradient(at 97% 14%, #57f491 0, #57f49100 50%), radial-gradient(at 95% 43%, #f3f55b 0, #f3f55b00 50%);
  background-size: 100% 100%;
  overflow-x: hidden;
}

ul {
  list-style: square;
}

li {
  padding: .25em;
}

canvas#previewcanvas {
  width: 80%;
  box-shadow: var(--colored-offset);
  display: block;
}

#container {
  margin: 1em 30vw 4em;
}

@media (max-width: 1300px) {
  #container {
    margin-left: 20vw;
    margin-right: 20vw;
  }
}

@media (max-width: 900px) {
  #container {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}

@media (max-width: 700px) {
  #container {
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

h1 {
  font-size: 4em;
  font-family: var(--title-font);
  color: var(--dark-text-color);
  margin: 0;
}

.smallerfont {
  font-size: 50%;
}

#intro {
  z-index: -10;
  margin-top: -1em;
  padding-bottom: 1.5em;
  position: relative;
  overflow: hidden;
  transform: rotate(353deg);
}

#intro h4 {
  color: var(--highlight-color);
  margin: 1em 0;
  font-size: 1.25em;
  font-weight: 600;
}

#intro em {
  color: var(--light-text-color);
  margin-left: 1em;
  font-size: 1.25em;
  font-weight: 500;
}

#intro ul {
  color: var(--light-text-color);
  letter-spacing: .02em;
  margin-top: 1.3em;
  margin-left: 4em;
  font-weight: 600;
  transform: rotate(5deg);
}

.tabs {
  z-index: 10;
  flex-flow: row;
  margin-bottom: 1em;
  display: flex;
  position: relative;
}

.tabs.previousnext {
  justify-content: right;
}

.tab {
  color: var(--button-text-color);
  background-color: var(--highlight-color);
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border-radius: .2em;
  margin-right: .8em;
  padding: .5em 1em .4em;
  font-size: 90%;
  text-decoration: none;
}

.tab.selectable:hover {
  background-color: var(--feedback-color);
  box-shadow: var(--colored-offset);
  margin-top: -.5em;
  margin-bottom: .5em;
}

.tab:active {
  background-color: var(--selected-color);
  color: var(--highlight-color);
}

.tab.selected {
  background-color: var(--selected-color);
  color: var(--inactive-button-text-color);
  box-shadow: var(--colored-offset-inactive);
  margin-top: -.5em;
  margin-bottom: .5em;
}

.tab:last-child {
  margin-right: 0;
}

#layers-form {
  flex-direction: column;
  margin-left: .5em;
  display: flex;
}

.layercontainer {
  flex-direction: column;
  margin-bottom: 2em;
  display: flex;
}

.layernamelabel {
  letter-spacing: .02em;
  word-spacing: .05em;
  font-weight: 700;
}

.filelabel {
  margin-top: 1em;
}

.attributeinput, .layernameinput {
  background: linear-gradient(90deg, #fff 0%, #fff0 40% 100%);
  border-width: 0;
  border-color: #fff;
}

.highlight {
  background-color: #fff;
  background-image: radial-gradient(at 38% 48%, #f7e626 0, #f7e62600 50%), radial-gradient(at 50% 23%, #f467a2 0, #67f46700 50%), radial-gradient(at 66% 1%, #f93940 0, #f9394000 50%), radial-gradient(at 9% 66%, #f7268f 0, #f7268f00 50%), radial-gradient(at 83% 49%, #f5f117 0, #3cf32b00 50%), radial-gradient(at 61% 36%, #f20d9e 0, #f20d9e00 50%);
}

.layer-thumbnail {
  width: 10vw;
  min-width: 64px;
  box-shadow: 10px 10px 50px -20px #ffffff80;
}

#uploadall, #mintbutton {
  background-color: var(--button-color);
  box-shadow: var(--colored-offset);
  color: var(--button-text-color);
  -webkit-user-select: none;
  user-select: none;
  border-width: 0;
  border-radius: 1em;
  margin-top: 5vw;
  margin-left: 2vw;
  padding: 1em;
}

#uploadall:hover, #mintbutton#hover {
  background-color: var(--feedback-color);
}

#uploadall:active, #mintbutton:active {
  background-color: var(--selected-color);
  color: var(--button-color);
  box-shadow: var(--colored-offset);
}

#uploadall.inactive {
  background-color: var(--inactive-color);
  color: var(--inactive-button-text-color);
  cursor: default;
}

#uploadall.inactive:hover {
  background-color: var(--inactive-color);
}

.waiting {
  color: var(--inactive-button-text-color);
  cursor: default;
  background-image: linear-gradient(270deg, #aec2fc, #66eb69, #ec85f4, #aec2fc);
  animation: 40s linear infinite scrollingBackground;
}

@keyframes scrollingBackground {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 1000vw 0;
  }
}

#mintbutton.done {
  background-color: var(--done-color);
  color: var(--inactive-button-text-color);
  cursor: default;
}

#mintbutton.done:hover {
  background-color: var(--done-color);
}

#mintbutton.failed {
  background-color: var(--fail-color);
  color: var(--inactive-button-text-color);
  cursor: default;
}

.gallery {
  display: block;
}

.gallerythumbnail {
  width: 32%;
  background: var(--background-color);
  margin-bottom: 1em;
  font-size: 70%;
  display: inline-block;
}

.gallerythumbnailname, .gallerythumbnailcreator {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 1em;
  margin-right: 1em;
  overflow: hidden;
}

.gallerythumbnail a {
  color: var(--button-color);
}

.gallerythumbnailimagesizer {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}

.gallerythumbnailimage {
  width: 100%;
  aspect-ratio: 1 / 1;
}

.tokeninfos {
  flex-flow: wrap;
  place-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.tokeninfos h3 {
  margin-left: 2em;
}

.aboutpage h5 {
  color: var(--light-text-color);
  margin-bottom: 0;
  margin-left: .5em;
  font-size: 1.5em;
  font-weight: 700;
}

.aboutpage ul {
  background-color: var(--background-color);
  color: var(--inactive-button-text-color);
  box-shadow: var(--colored-offset);
  margin-top: -2em;
  margin-bottom: 2.5em;
  padding-top: 2.3em;
  padding-bottom: 1em;
  font-weight: 500;
}

/*# sourceMappingURL=index.cb591eb5.css.map */
