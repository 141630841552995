:root {
  --background-color: white;
  --dark-text-color: rgba(0, 0, 0, 0.8);
  --subdued-text-color: #765e8e;
  --light-text-color: hsla(331, 100%, 60%, 0.65);
  --button-text-color: whitesmoke;
  --inactive-button-text-color: rgba(0, 0, 0, 0.5);
  --highlight-color: hotpink;
  --button-color: hotpink;
  --button-shadow-color: rgba(159, 239, 247, 255);
  --feedback-color: rgb(249, 57, 153);
  --inactive-color: lightgrey;
  --selected-color: white;
  --done-color: rgb(193, 254, 193);
  --fail-color: rgb(231, 58, 70);

  --title-font: 'Bungee Shade', cursive;
  --text-font: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;

  --input-left-padding: 0.25em;

  --colored-offset: 0.5em 0.5em 0 0 var(--button-shadow-color);
  --colored-offset-inactive: 0.5em 0.5em 0 0 var(--inactive-color);

}

/*
  Remove default margin
*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/*
  width: 100% Allow percentage-based heights in the application
*/

html,
body {
  width: 100%;
  min-height: 100vh;

  font-family: var(--text-font);
  color: var(--dark-text-color);
}

body {

  overflow-x: hidden;

  background-color: #edf7f7;

  background-size: 100% 100%;

  /* https://www.color-morph.com */

  background-image: radial-gradient(at 27% 42%, hsla(288, 86%, 70%, 1) 0, hsla(288, 86%, 70%, 0) 50%),
    radial-gradient(at 31% 51%, hsla(144, 86%, 56%, 1) 0, hsla(144, 86%, 56%, 0) 50%),
    /* radial-gradient(at 69% 87%, hsla(34, 87%, 68%, 1) 0, hsla(34, 87%, 68%, 0) 50%), */
    radial-gradient(at 97% 14%, hsla(142, 88%, 65%, 1) 0, hsla(142, 88%, 65%, 0) 50%),
    /* radial-gradient(at 27% 95%, hsla(47, 92%, 68%, 1) 0, hsla(47, 92%, 68%, 0) 50%), */
    radial-gradient(at 95% 43%, hsla(61, 89%, 66%, 1) 0, hsla(61, 89%, 66%, 0) 50%);
}

ul {
  list-style: square;
}

li {
  padding: 0.25em;
}

canvas#previewcanvas {
  display: block;
  width: 80%;
  box-shadow: var(--colored-offset);

}

#container {
  margin-left: 30vw;
  margin-right: 30vw;
  margin-top: 1em;
  margin-bottom: 4em;
}

@media (max-width: 1300px) {

  #container {
    margin-left: 20vw;
    margin-right: 20vw;
  }

}

@media (max-width: 900px) {

  #container {
    margin-left: 10vw;
    margin-right: 10vw;
  }

}

@media (max-width: 700px) {

  #container {
    margin-left: 5vw;
    margin-right: 5vw;
  }

}

h1 {
  font-size: 4em;
  font-family: var(--title-font);
  color: var(--dark-text-color);
  margin: 0;
}

.smallerfont {
  font-size: 50%;
}

#intro {
  overflow: hidden;
  transform: rotate(353deg);
  margin-top: -1em;
  padding-bottom: 1.5em;
  position: relative;
  z-index: -10;
}

#intro h4 {
  font-size: 1.25em;
  font-weight: 600;
  color: var(--highlight-color);
  margin: 0;
  margin-top: 1em;
  margin-bottom: 1em;
}

#intro em {
  color: var(--light-text-color);
  font-size: 1.25em;
  font-weight: 500;
  margin-left: 1em;
}

#intro ul {
  transform: rotate(5deg);
  margin-top: 1.3em;
  margin-left: 4em;
  color: var(--light-text-color);
  font-weight: 600;
  letter-spacing: 0.02em;
}

.tabs {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  flex-wrap: nowrap;
  position: relative;
  z-index: 10;
}

.tabs.previousnext {
  justify-content: right;
}

.tab {
  color: var(--button-text-color);
  background-color: var(--highlight-color);

  text-decoration: none;
  text-align: center;

  /* top | right | bottom | left */
  padding: 0.5em 1em 0.4em 1em;

  border-top-left-radius: 0.2em;
  border-top-right-radius: 0.2em;
  border-bottom-left-radius: 0.2em;
  border-bottom-right-radius: 0.2em;

  margin-right: 0.8em;
  font-size: 90%;

  user-select: none;
}

.tab.selectable:hover {
  background-color: var(--feedback-color);
  box-shadow: var(--colored-offset);
  margin-bottom: 0.5em;
  margin-top: -0.5em;
}

.tab:active {
  background-color: var(--selected-color);
  color: var(--highlight-color);
}

.tab.selected {
  background-color: var(--selected-color);
  color: var(--inactive-button-text-color);
  margin-bottom: 0.5em;
  margin-top: -0.5em;
  box-shadow: var(--colored-offset-inactive);
}

.tab:last-child {
  margin-right: 0;
}

#layers-form {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
}

.layercontainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}

.layernamelabel {

  font-weight: 700;
  letter-spacing: 0.02em;
  word-spacing: 0.05em;
}

.filelabel {
  margin-top: 1em;
}

/* https://cssgradient.io */

.attributeinput,
.layernameinput {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 100%);
  border-color: #ffffffff;
  border-width: 0;
}


.highlight {

  /* https://www.color-morph.com */

  background-color: #ffffff;
  background-image: radial-gradient(at 38% 48%, hsla(55, 93%, 56%, 1) 0, hsla(55, 93%, 56%, 0) 50%),
    radial-gradient(at 50% 23%, rgb(244, 103, 162) 0, hsla(120, 86%, 68%, 0) 50%),
    radial-gradient(at 66% 1%, hsla(358, 94%, 60%, 1) 0, hsla(358, 94%, 60%, 0) 50%),
    radial-gradient(at 9% 66%, hsla(330, 93%, 56%, 1) 0, hsla(330, 93%, 56%, 0) 50%),
    radial-gradient(at 83% 49%, rgb(245, 241, 23) 0, hsla(115, 89%, 56%, 0) 50%),
    radial-gradient(at 61% 36%, hsla(322, 90%, 50%, 1) 0, hsla(322, 90%, 50%, 0) 50%);
}

.layer-thumbnail {
  width: 10vw;
  min-width: 64px;

  /* https://www.cssmatic.com/box-shadow */
  box-shadow: 10px 10px 50px -20px rgba(255, 255, 255, 0.5);
}

#uploadall,
#mintbutton {
  margin-top: 5vw;
  margin-left: 2vw;
  padding: 1em;
  background-color: var(--button-color);
  border-radius: 1em;
  border-width: 0;
  box-shadow: var(--colored-offset);
  color: var(--button-text-color);
  user-select: none;
}

#uploadall:hover,
#mintbutton#hover {
  background-color: var(--feedback-color);
}

#uploadall:active,
#mintbutton:active {
  background-color: var(--selected-color);
  color: var(--button-color);
  box-shadow: var(--colored-offset);
}

#uploadall.inactive {
  background-color: var(--inactive-color);
  color: var(--inactive-button-text-color);
  cursor: default;
}

#uploadall.inactive:hover {
  background-color: var(--inactive-color);
}

.waiting {
  background-image: linear-gradient(270deg, #aec2fc, #66eb69, #ec85f4, #aec2fc);
  animation: scrollingBackground 40s infinite linear;
  color: var(--inactive-button-text-color);
  cursor: default;
}

@keyframes scrollingBackground {
  0% {
    background-position: 0 0
  }

  100% {
    background-position: 1000vw 0
  }
}

#mintbutton.done {
  background-color: var(--done-color);
  color: var(--inactive-button-text-color);
  cursor: default;
}

#mintbutton.done:hover {
  background-color: var(--done-color);
}

#mintbutton.failed {
  background-color: var(--fail-color);
  color: var(--inactive-button-text-color);
  cursor: default;
}

/* gallery  */

.gallery {
  display: block;

}

.gallerythumbnail {
  width: 32%;
  background: var(--background-color);
  display: inline-block;
  margin-bottom: 1em;

  font-size: 70%;
}

.gallerythumbnailname,
.gallerythumbnailcreator {
  margin-left: 1em;
  margin-right: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}


.gallerythumbnail a {
  color: var(--button-color)
}

/* 
      using new aspect ratio property. Sadly not supported on Safari version released before september 2021 
      That's why we also use the gallerythumbnailimagesizer padding trick, that we could drop in a year or two
      more solutions: https://stackoverflow.com/questions/5445491/height-equal-to-dynamic-width-css-fluid-layout 
      */

.gallerythumbnailimagesizer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}


.gallerythumbnailimage {
  width: 100%;
  aspect-ratio: 1 / 1;
}


.tokeninfos {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.tokeninfos h3 {
  margin-left: 2em;
}

.aboutpage h5 {
  color: var(--light-text-color);
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 0em;
  margin-left: 0.5em;

}

.aboutpage ul {
  font-weight: 500;
  background-color: var(--background-color);
  color: var(--inactive-button-text-color);
  box-shadow: var(--colored-offset);
  margin-top: -2em;
  margin-bottom: 2.5em;
  padding-top: 2.3em;
  padding-bottom: 1em;
}